import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import HttpActions from '../../../../actions/HttpActions';

function SendFileButton() {
    // Store Interfaces
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    // Called when the button is clicked
    const buttonClickedEvent = event => {
        event.preventDefault();
        if(state.ui.fileUploaded) {
            if(state.ui.emails_match) {
                dispatch(HttpActions.sendFile(state.ui.form_file_upload_name, state.ui.form_file_upload_email, state.ui.file));
            }
        };
    };

    return (
        <button className="send_file_button" onClick={buttonClickedEvent} disabled={!state.ui.fileUploaded || state.http.fileSent || state.http.fileReceived}>
            Start
        </button>
    );
};

export default SendFileButton;