import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import UploadFileButton from './ui/UploadFileButton';
import SendFileButton from './ui/SendFileButton';
import FormActions from '../../../actions/FormActions';

function FileUploadForm() {
    // Store Interfaces
    const state = useSelector(state => state.ui);
    const dispatch = useDispatch();

    // Called when text field is changed
    const textFieldChangedEvent = event => {
        dispatch(FormActions.textFieldChanged("file_upload", event.target.name, event.target.value));
    };
    
    // Called when email field is changed
    const emailFieldChangedEvent = event => {
        dispatch(FormActions.emailFieldChanged("file_upload", event.target.name, event.target.value));
    };

    // Called when email field is unfocused - validates value
    const validateEmailFieldEvent = event => {
        var value = event.target.value;

        // If it is the confirm email field make sure it matches
        if(event.target.name === 'confirm_email') {
            if(value === state.form_file_upload_email) {
                dispatch(FormActions.emailsAreMatched());
            } else {
                dispatch(FormActions.emailsNotMatched());
            };
        } else if(event.target.name === 'email') {
            if(value === state.form_file_upload_confirm_email) {
                dispatch(FormActions.emailsAreMatched());
            } else {
                dispatch(FormActions.emailsNotMatched());
            };
        };
    };

    // If emails do not match show an error
    var email_match_error = null;
    if(!state.emails_match) {
        email_match_error = <label id="email_match_error">Emails do not match!</label>;
    };

    return(
        <div className="section_form" id="form_file_upload">
            <label className="label_file_upload">
                Name:
                <input type="text" className="input_fullname" name="name" value={state.form_file_upload_name} onChange={textFieldChangedEvent} placeholder="Enter full name." />
            </label>
            <label className="label_file_upload">
                E-mail Address:
                <input type="email" className="input_email" name="email" value={state.form_file_upload_email} onChange={emailFieldChangedEvent} onBlur={validateEmailFieldEvent} placeholder="Enter your e-mail address." />
            </label>
            <label className="label_file_upload">
                Confirm E-mail:
                <input type="email" className="input_confirm_email" id="confirm_email" name="confirm_email" value={state.form_file_upload_confirm_email} onChange={emailFieldChangedEvent} onBlur={validateEmailFieldEvent} placeholder="Re-enter your e-mail address." />
            </label>
            {email_match_error}
            <UploadFileButton /> <span>{state.fileUploaded ? state.file.name : "No file selected"}</span>
            <SendFileButton />
        </div>
    );
};

export default FileUploadForm;