import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import FormActions from '../../../actions/FormActions';
import RunSimulationButton from './ui/RunSimulationButton';

function SimulationOptionsForm() {
    // Store Interfaces
    const state = useSelector(state => state.ui);
    const dispatch = useDispatch();

    // Called when a radio button is selected to change the simulation method
    const simulationMethodChangedEvent = event => {
        dispatch(FormActions.simulationMethodChanged(event.target.value));
    }

    // Called when a number field is changed
    const numberFieldChangedEvent = event => {
        dispatch(FormActions.numberFieldChanged("simulation_options", event.target.name, event.target.value));
    };

    // Called number field is unfocused - validates value
    const validateNumberFieldEvent = event => {
        var value = Number(event.target.value);
        if(isNaN(value)) { // If input is not a number set to default value
            value = Number(event.target.default);
        } else if(value < event.target.min) { // If below minimum set to minimum value
            value = event.target.min;
        } else if(value > event.target.max) { // If above maximum set to maximum value
            value = event.target.max;
        };

        // If value was updated dispatch new value
        if(value !== event.target.value) {
            dispatch(FormActions.numberFieldChanged("simulation_options", event.target.name, value));
        };
    };

    return(
        <div className="section_form" id="form_simulation_options">
            <label className="label_simulation_options" id="label_simulation_radio">
                Simulation Method:
                <div className="simulation_options_radio">
                    <label htmlFor="sim_method_ode">ODE</label>
                    <input type="radio" className="sim_method_radio" name="sim_method" id="sim_method_ode"
                        value="ode" checked={state.simulationMethod === 'ode'} onChange={simulationMethodChangedEvent} />
                    <label htmlFor="sim_method_ssa">SSA</label>
                    <input type="radio" className="sim_method_radio" name="sim_method" id="sim_method_ssa"
                        value="ssa" checked={state.simulationMethod === 'ssa'} onChange={simulationMethodChangedEvent} />
                </div>
            </label>
            <label className="label_simulation_options">
                Number of Runs:
                <input type="number" className="input_number_runs" name="numberOfRuns" min="1" max="1000" step="1" value={state.numberOfRuns} default="1" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label className="label_simulation_options">
                Simulation Time Limit:
                <input type="number" className="input_time_limit" name="timeLimit" min="0.0" max="1E9" step="any"  value={state.timeLimit} default="0.5" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <h2 className="form_section_header">*Required for SSA simulations.</h2>
            <label className="label_simulation_options">
                *Print Interval:
                <input type="number" className="input_interval" name="printInterval" min="0.0" max="1E9" step="any"  value={state.printInterval} default="0.5" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label className="label_simulation_options">
                *Simulation Time Step:
                <input type="number" className="input_time_step" name="timeStep" min="0.0" max="1E9" step="any"  value={state.timeStep} default="0.5" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label className="label_simulation_options">
                *Minimum Time Step:
                <input type="number" className="input_min_time_step" name="minimumTimeStep" min="0.0" max="1E9" step="any"  value={state.minimumTimeStep} default="0.5" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label className="label_simulation_options">
                *Random Seed:
                <input type="number" className="input_random_seed" name="randomSeed" min="0" max="1E9" step="1"  value={state.randomSeed} default="1" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <h3 className="form_section_sub_header">Error values must be positive doubles.</h3>
            <label className="label_simulation_options">
                *Absolute Error:
                <input type="number" className="input_absolute_error" name="absoluteError" min="0.0" max="1E9" step="any"  value={state.absoluteError} default="1.0E-9" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label className="label_simulation_options">
                *Relative Error:
                <input type="number" className="input_relative_error" name="relativeError" min="0.0" max="1E9" step="any"  value={state.relativeError} default="1.0E-9" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <RunSimulationButton />
        </div>
    );
};

export default SimulationOptionsForm;