import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import HttpActions from '../../../../actions/HttpActions';

function SubmitConcentrationsButton() {
    // Store Interfaces
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    // Called when the button is clicked
    const buttonClickedEvent = event => {
        event.preventDefault();

        // Send the concentration input values to the server
        var minimumConcentration = state.ui.minimumConcentration;
        var maximumConcentration = state.ui.maximumConcentration;
        var numberOfSteps = state.ui.numberOfSteps;
        var eventTime = state.ui.eventTime;
        dispatch(HttpActions.submitConcentrationValues(minimumConcentration, maximumConcentration, numberOfSteps, eventTime));
    };

    return (
        <button className="submit_concentrations_button" onClick={buttonClickedEvent} >
            Submit
        </button>
    );
};

export default SubmitConcentrationsButton;