// List of all action types within the various action files
export const types = {
    PAGE_STATE_CHANGED: 'PAGE_STATE_CHANGED',
    ALL_ACCORDIONS_CLOSED: 'ALL_ACCORDIONS_CLOSED',
    ACCORDION_CHANGED: 'ACCORDION_CHANGED',
    FIELD_VALUE_CHANGED: 'FIELD_VALUE_CHANGED',
    EMAILS_NOT_MATCHED: 'EMAILS_NOT_MATCHED',
    EMAILS_ARE_MATCHED: 'EMAILS_ARE_MATCHED',
    INPUT_SPECIES_SELECTED: 'INPUT_SPECIES_SELECTED',
    OUTPUT_SPECIES_SELECTED: 'OUTPUT_SPECIES_SELECTED',
    SIMULATION_METHOD_CHANGED: 'SIMULATION_METHOD_CHANGED',
    SESSION_ID_REQUESTED: 'SESSION_ID_REQUESTED',
    SESSION_ID_ACQUIRED: 'SESSION_ID_ACQUIRED',
    SESSION_ID_ERROR: 'SESSION_ID_ERROR',
    FILE_UPLOADED: 'FILE_UPLOADED',
    RESET_SPECIES_SELECTED: 'RESET_SPECIES_SELECTED',
    SPECIES_SELECTED: 'SPECIES_SELECTED',
    FILE_SENT: 'FILE_SENT',
    FILE_RECEIVED: 'FILE_RECEIVED',
    FILE_SEND_ERROR: 'FILE_UPLOAD_ERROR',
    FILE_READ_ERROR: 'FILE_READ_ERROR',
    FILE_SBML_ERROR: 'FILE_SBML_ERROR',
    SPECIES_SENT: 'SPECIES_SENT',
    REACTION_MAP_RECEIVED: 'REACTION_MAP_RECEIVED',
    SPECIES_SEND_ERROR: 'SPECIES_SEND_ERROR',
    SPECIES_READ_ERROR: 'SPECIES_READ_ERROR',
    CONCENTRATION_VALUES_SENT: 'CONCENTRATION_VALUES_SENT',
    CONCENTRATIONS_SEND_ERROR: 'CONCENTRATIONS_SEND_ERROR',
    CONCENTRATIONS_READ_ERROR: 'CONCENTRATIONS_READ_ERROR',
    SIMULATION_OPTIONS_SENT: 'SIMULATION_OPTIONS_SENT',
    SIMULATION_SEND_ERROR: 'SIMULATION_SEND_ERROR',
    SIMULATION_RUN_ERROR: 'SIMULATION_RUN_ERROR',
    OUTPUT_DATA_PARSING: 'OUTPUT_DATA_PARSING',
    OUTPUT_DATA_PARSED: 'OUTPUT_DATA_PARSED',
    OUTPUT_DATA_ERROR: 'OUTPUT_DATA_ERROR',
    IN_OUT_PLOT_RECEIVED: 'IN_OUT_PLOT_RECEIVED',
    PRECURSOR_DATA_RECEIVED: 'PRECURSOR_DATA_RECEIVED',
    PROPERTIES_RECEIVED: 'PROPERTIES_RECEIVED',
    DISPLAY_DATA_RECEIVED: 'DISPLAY_DATA_RECEIVED'
};

// List of input action subtypes
export const inputs = {
    TEXT: 'TEXT',
    EMAIL: 'EMAIL',
    NUMBER: 'NUMBER'
};