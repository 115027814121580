import {inputs, types} from '../actions/ActionTypes.js';

/* The default state for the application */
const defaultState = {
    currentPage: 'application',
    section_file_upload_collapsed: false,
    section_species_selection_collapsed: true,
    section_reaction_map_collapsed: true,
    section_concentrations_collapsed: true,
    section_options_collapsed: true,
    section_simulation_plot_collapsed: true,
    section_entropy_plot_collapsed: true,
    section_information_collapsed: true,
    section_download_files_collapsed: true,
    form_file_upload_name: "",
    form_file_upload_email: "",
    form_file_upload_confirm_email: "",
    emails_match: true,
    fileUploaded: false,
    file: null,
    input_species_option_selected: 0,
    output_species_option_selected: 0,
    speciesSelected: "No species selected",
    minimumConcentration: 0,
    maximumConcentration: 0,
    numberOfSteps: 0,
    eventTime: 0,
    simulationMethod: 'ode',
    numberOfRuns: 1,
    timeLimit: 0,
    printInterval: 0,
    timeStep: 0,
    minimumTimeStep: 0,
    randomSeed: 1,
    absoluteError: 1.0E-9,
    relativeError: 1.0E-9,
    simulationOutputSelection: 0
};

const UIReducer = (state = defaultState, action) => {
    switch(action.type) {
        case types.PAGE_STATE_CHANGED:
            return {...state, currentPage: action.page};
        case types.ALL_ACCORDIONS_CLOSED:
            return {...state, section_file_upload_collapsed: true, section_species_selection_collapsed: true, section_reaction_map_collapsed: true, section_concentrations_collapsed: true, section_options_collapsed: true};
        case types.ACCORDION_CHANGED:
            var section_string = action.name + "_collapsed";
            var collapseValue = state[section_string];
            return {...state, [section_string]: !collapseValue};
        case types.FILE_UPLOADED:
            return {...state, file: action.file, fileUploaded: true};
        case types.FIELD_VALUE_CHANGED:
            var fieldStateName = "form_" + action.form + "_" + action.field;
            switch(action.input) {
                case inputs.TEXT:
                    return {...state, [fieldStateName]: action.text};
                case inputs.EMAIL:
                    return {...state, [fieldStateName]: action.email};
                case inputs.NUMBER:
                    return {...state, [action.field]: action.number};
                default:
                    return state;
            };
        case types.EMAILS_NOT_MATCHED:
            return {...state, emails_match: false};
        case types.EMAILS_ARE_MATCHED:
            return {...state, emails_match: true};
        case types.INPUT_SPECIES_SELECTED:
            return {...state, input_species_option_selected: action.species, input_species_selected: true};
        case types.OUTPUT_SPECIES_SELECTED:
            return {...state, output_species_option_selected: action.species, output_species_selected: true};
        case types.RESET_SPECIES_SELECTED:
            return {...state};
        case types.SPECIES_SELECTED:
            return {...state, speciesSelected: action.species};
        case types.SIMULATION_METHOD_CHANGED:
            return {...state, simulationMethod: action.method};
        default:
            return state;
    };
};

export default UIReducer;