import React from 'react';
import './styles.css';
import {useSelector} from 'react-redux';
import {Graphviz} from 'graphviz-react';

function SpeciesSelectForm() {
    // Store Interfaces
    const state = useSelector(state => state);

    //DOM Element Initialization
    var reaction_map = null;
    if(state.http.reactionMapReceived && !state.ui.section_reaction_map_collapsed) {
        reaction_map = 
            <Graphviz className="reaction_map"
                dot={state.http.reactionMapText} 
                options={{
                    fit: true,
                    height: 500,
                    width: 500,
                    zoom: true,
                    tweenPaths: false,
                    tweenShapes: false
                }}
            />;
    };

    return(
        <div className="section_form" id="form_reaction_map">
            {reaction_map}
        </div>
    );
};

export default SpeciesSelectForm;