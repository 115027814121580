import React from 'react';
import './MenubarTab.css';

function MenubarTab(props) {
    return(
        <React.Fragment>
            <input type="radio"
                className="menu_tab"
                name="menu_tabs"
                value={props.tabValue}
                id={props.tabName}
                checked={props.tabValue === props.pageSelected} 
                onChange={props.onPageChanged} />
            <label htmlFor={props.tabName}>{props.tabLabel}</label>
        </React.Fragment>
    );
};

export default MenubarTab;