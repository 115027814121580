import React from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import Menubar from './components/layout/Menubar';
import PanelContainer from './components/layout/PanelContainer';
import PanelOutput from './components/layout/PanelOutput';
import HttpActions from './actions/HttpActions';

function App() {
    // Store Interfaces
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    return (
        <BrowserRouter>
            <Menubar />
                {/* Tab bar for switching panels */}
            <Switch>
                <Route path="/output/" children={<PanelOutput />} />
                <Route children={<PanelContainer />} />
            </Switch>
            <div className="footer">
                <div className="nsf_logo_div">
                    <img src={process.env.PUBLIC_URL + "/nsfLogo.png"} alt="US National Science Foundation" className="logo" id="nsf_logo" />
                    <p>This material is based upon work supported by the National Science Foundation. Disclaimer: Any opinions, findings, and conclusions or recommendations expressed in this material are those of the author(s) and do not necessarily reflect the views of the National Science Foundation. Grant no. 1816969.</p>
                </div>
                <div className="lockup_logo_div">
                    <img src={process.env.PUBLIC_URL + "/lockup.jpg"} alt="UNL MBite Team" className="logo" id="lockup_logo" />
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
