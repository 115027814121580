import React, {useEffect} from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import Accordion from './ui/Accordion.js';
import UIActions from '../../actions/UIActions';
import HttpActions from '../../actions/HttpActions';
import FileUploadForm from './form/FileUploadForm';
import SpeciesSelectForm from './form/SpeciesSelectForm';
import ReactionMapForm from './form/ReactionMapForm';
import InitialConcentrationForm from './form/InitialConcentrationForm';
import SimulationOptionsForm from './form/SimulationOptionsForm';

function PanelApplication() {
    /*
        This panel contains the simulation inputs and outputs
    */

    // Store Interfaces
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        // Check if user has a session when page is loaded
        if(state.http.shouldRequestSessionId) {
            console.log("sessionId requested!");
            dispatch(HttpActions.requestSessionId());
        };
    });

    // Called whenever an accordion section is clicked
    const accordionSectionClicked = event => {
        dispatch(UIActions.accordionChanged(event.target.id));
    }

    return(
        <div className="panel_view" id="panel_application">
            {/* These are the expandable sections on the page containing all the simulation options */}
            <Accordion name="section_file_upload" collapsed={state.ui.section_file_upload_collapsed.toString()} title="Upload File" content={<FileUploadForm />} sectionClicked={accordionSectionClicked} />
            <Accordion name="section_species_selection" collapsed={state.ui.section_species_selection_collapsed.toString()} title="Select Species" content={<SpeciesSelectForm />} sectionClicked={accordionSectionClicked} />
            <Accordion name="section_reaction_map" collapsed={state.ui.section_reaction_map_collapsed.toString()} title="Reaction Map" content={<ReactionMapForm />} sectionClicked={accordionSectionClicked} />
            <Accordion name="section_concentrations" collapsed={state.ui.section_concentrations_collapsed.toString()} title="Initial Concentrations of Input" content={<InitialConcentrationForm />} sectionClicked={accordionSectionClicked} />
            <Accordion name="section_options" collapsed={state.ui.section_options_collapsed.toString()} title="Simulation Options" content={<SimulationOptionsForm />} sectionClicked={accordionSectionClicked} />
        </div>
    );
};

export default PanelApplication;