import React from 'react';
import './styles.css';
import {useSelector} from 'react-redux';
import {Line} from 'react-chartjs-2';

function EntropyPlotForm() {
    // Store Interfaces
    const state = useSelector(state => state);

    //DOM Element Initialization
    var entropy_plot = null;
    if(state.http.inOutPlotReceived && !state.ui.section_entropy_plot_collapsed) {
        var data = state.http.inOutPlotText.split("\n");
        var labels = data[0].split(",");
        var inputLabel = labels[0];
        var outputLabel = labels[1];
        var inputData = data[1].split(",");
        var outputData = data[2].split(",");
        for (var i = 0; i < inputData.length; i++) {
            inputData[i] = parseFloat(inputData[i]);
            outputData[i] = parseFloat(outputData[i]);
        };

        var plot_data = {
            labels: inputData,
            datasets: [{
                data: outputData,
                label: outputLabel,
                borderColor: "#8e5ea2",
                fill: false
            }]
        };

        var plot_options = {
            elements: {
                point: {
                    radius: 2
                }
            },
            scales: {
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'OUTPUT: ' + outputLabel + '(mole/liter)'
                    }
                }],
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'INPUT: ' + inputLabel + '(mole/liter)'
                    }
                }]
            }
        };

        entropy_plot = <Line height={800} width={1000} data={plot_data} options={plot_options} />
    };

    return(
        <div className="section_form" id="form_entropy_plot">
            <h3>Input Concentration vs Output Concentration</h3>
            {entropy_plot}
        </div>
    );
};

export default EntropyPlotForm;