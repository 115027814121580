import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import FormActions from '../../../actions/FormActions';
import SubmitConcentrationsButton from './ui/SubmitConcentrationsButton';

function InitialConcentrationForm() {
    // Store Interfaces
    const state = useSelector(state => state.ui);
    const dispatch = useDispatch();

    // Called when a number field is changed
    const numberFieldChangedEvent = event => {
        dispatch(FormActions.numberFieldChanged("initial_concentration", event.target.name, event.target.value));
    };

    // Called number field is unfocused - validates value
    const validateNumberFieldEvent = event => {
        var value = Number(event.target.value);
        if(isNaN(value)) { // If input is not a number set to default value
            value = Number(event.target.default);
        } else if(value < event.target.min) { // If below minimum set to minimum value
            value = event.target.min;
        } else if(value > event.target.max) { // If above maximum set to maximum value
            value = event.target.max;
        };

        // If value was updated dispatch new value
        if(value !== event.target.value) {
            dispatch(FormActions.numberFieldChanged("initial_concentration", event.target.name, value));
        };
    };

    return(
        <div className="section_form" id="form_initial_concentration">
            <label className="label_initial_concentration" id="label_species_name">
                Species Selected: 
                <label className="name_species_selected">{state.speciesSelected}</label>
            </label>
            <label className="label_initial_concentration">
                Minimum Concentration:
                <input type="number" className="input_min_concentration" name="minimumConcentration" min="0.0" max="1E9" step="any" value={state.minimumConcentration} default="0.5" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label className="label_initial_concentration">
                Maximum Concentration:
                <input type="number" className="input_max_concentration" name="maximumConcentration" min="0.0" max="1E9" step="any" value={state.maximumConcentration} default="0.5" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label className="label_initial_concentration">
                Number of Steps:
                <input type="number" className="input_steps" name="numberOfSteps" min="1" max="1000" step="1" value={state.numberOfSteps} default="1" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <label className="label_initial_concentration">
                Event Time:
                <input type="number" className="input_event_time" name="eventTime" min="0" max="1000" step="any" value={state.eventTime} default="1" onChange={numberFieldChangedEvent} onBlur={validateNumberFieldEvent} />
            </label>
            <SubmitConcentrationsButton />
        </div>
    );
};

export default InitialConcentrationForm;