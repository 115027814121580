import {combineReducers} from 'redux';
import UIReducer from './UIReducer';
import HttpReducer from './HttpReducer';

/* The root reducer containing nested reducers */
const RootReducer = combineReducers({
    ui: UIReducer,
    http: HttpReducer
});

export default RootReducer;