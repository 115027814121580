import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import UIActions from '../../../../actions/UIActions';

function UploadFileButton() {
    // Store Interfaces
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    // Called when the button is clicked
    const buttonClickedEvent = event => {
        event.preventDefault();
        document.getElementById('upload_file').click();
    };

    // Called when a file is selected
    const fileUploadEvent = event => {
        const file = event.target.files[0];
        dispatch(UIActions.fileUploaded(file));
        // Setting the value to null allows the same file to be loaded again immediately
        event.target.value = null;
    };

    return (
        <form encType="multipart/form-data">
            <button className="upload_file_button" onClick={buttonClickedEvent} disabled={state.http.fileSent}>
                Choose File
            </button>
            <input type="file" name="uploadFile" id="upload_file" onChange={fileUploadEvent} style={{display: "none"}} />
        </form>
    );
};

export default UploadFileButton;