import {types, inputs} from './ActionTypes.js';

/* Action called when a text field is changed */
const textFieldChanged = (formName, fieldName, newText) => {
    return {
        type: types.FIELD_VALUE_CHANGED,
        input: inputs.TEXT,
        form: formName,
        field: fieldName,
        text: newText
    };
};

/* Action called when an email field is changed */
const emailFieldChanged = (formName, fieldName, newEmail) => {
    return {
        type: types.FIELD_VALUE_CHANGED,
        input: inputs.EMAIL,
        form: formName,
        field: fieldName,
        email: newEmail
    };
};

/* Action called when a number field is changed */
const numberFieldChanged = (formName, fieldName, newNumber) => {
    return {
        type: types.FIELD_VALUE_CHANGED,
        input: inputs.NUMBER,
        form: formName,
        field: fieldName,
        number: newNumber
    };
};

/* Action called when email addresses don't match */
const emailsNotMatched = () => {
    return {
        type: types.EMAILS_NOT_MATCHED
    };
};

/* Action called when email addresses do match */
const emailsAreMatched = () => {
    return {
        type: types.EMAILS_ARE_MATCHED
    };
};

/* Action called when input species is selected from dropdown */
const inputSpeciesSelected = (species) => {
    return {
        type: types.INPUT_SPECIES_SELECTED,
        species: species
    };
};

/* Action called when output species is selected from dropdown */
const outputSpeciesSelected = (species) => {
    return {
        type: types.OUTPUT_SPECIES_SELECTED,
        species: species
    };
};

/* Action called when simulation method is changed */
const simulationMethodChanged = (newMethod) => {
    return {
        type: types.SIMULATION_METHOD_CHANGED,
        method: newMethod
    };
};

const FormActions = {
    textFieldChanged,
    emailFieldChanged,
    numberFieldChanged,
    emailsNotMatched,
    emailsAreMatched,
    inputSpeciesSelected,
    outputSpeciesSelected,
    simulationMethodChanged
};

export default FormActions;