import React from 'react';
import './styles.css';

function PanelTutorial() {
    //DOM Element Initialization
    
    return(
        <div className="panel_view" id="panel_tutorial">
            <h1 className="section_title" id="title_tutorial">Tutorial</h1>
            <div className="part_tutorial">
                <img src={process.env.PUBLIC_URL + "/fileInput.png"} alt="File Input" className="part_img" />
                <div className="part_tutorial_text">
                    <h2 className="part_title">Uploading a File</h2>
                    <p>The page will open with the "Upload File" section automatically opened. To submit an SBML file for analysis begin by entering in your name and your e-mail in the specified fields. If your e-mails do not match, then you will be notified and asked to make sure that they do before your file can be analyzed. After entering in your name and e-mail, click the "Chose File" button. This will open a file explorer so you can select the SMBL file you want to analyze. After selecting your file, the name will appear next to the "Chose File" button. Then, click the submit button to begin analyzing your file. When you click submit, the page will create a list of your species, as well as the units they're defined in. The "Upload File" section will close, and the "Select Species" section will open.</p>
                    </div>
            </div>
            <div className="part_tutorial">
                <img src={process.env.PUBLIC_URL + "/speciesSelect.png"} alt="Species Selection" className="part_img" />
                <div className="part_tutorial_text">
                    <h2 className="part_title">Selecting Species for Analysis</h2>
                    <p>Once you submit a file, the "Select Species" section will open. Here you will be greeted with two dropdown menus with all of your species listed. The left-hand dropdown menu is used to select your input species, and the right-hand dropdown menu allows you to select your output species. After clicking "Submit", the page will analyze your SBML file for various reactions. The "Reaction Map" and "Initial Concentrations of Input" sections will open.</p>
                </div>
            </div>
            <div className="part_tutorial">
                <img src={process.env.PUBLIC_URL + "/map.png"} alt="Reaction Map" className="part_img" />
                <div className="part_tutorial_text">
                    <h2 className="part_title">The Reaction Map</h2>
                    <p>After submitting your species, the "Reaction Map" section will open. The reaction map is a visual representation of all the reactions related to your input and output species. Displayed on the left-hand side of the map is a legend for the symbols and colors used in the map. This section will also appear on the page generated with your analysis results, and the map will appear exactly as it does on the input page with the same information. From the output page, you will be able to download a picture of the map. See the tutorial section on downloading your files for more information.</p>
                </div>
            </div>
            <div className="part_tutorial">
                <img src={process.env.PUBLIC_URL + "/intCon.png"} alt="Initial Concentrations" className="part_img" />
                <div className="part_tutorial_text">
                    <h2 className="part_title">Selecting Input Concentrations</h2>
                    <p>After submitting your species, the "Initial Concentrations of Input" section will open. This section allows you to vary the beginning concentration of your input species without editing the SBML file. All units are defined in the file, and are displayed next to the text boxes. The minimum Concentration is an integer or decimal number that determines the smallest concentration that the input species will be set to in the simulation. The maximum Concentration is an integer or decimal number that determines the largest concentration that the input species will be set to in the simulation. The number of steps is an integer that determines how many steps in the concentration will be simulated. Each step will use a different concentration for the input species, starting at the minimum concentration for the first simulation. Each step after the first will set the input species concentration to a value between the minimum and maximum concentration, and each step’s input concentration is evenly spaced. The last step uses the maximum concentration for the input species concentration. If Number of Steps is set to 1, then a single step will run using the minimum concentration. The event Time is an integer representing the time at which the concentration is set in the simulation, given in seconds. The simulation runs normally before and after the event time. If no thing is entered into the boxes, then the simulation will simply use the data defined in the file. After selecting and submitting your input concentrations, the "Reaction Map" and "Initial Concentrations of Input" sections will close, and the "Simulation Options" section will open.</p>
                </div>
            </div>
            <div className="part_tutorial">
                <img src={process.env.PUBLIC_URL + "/simOpt.png"} alt="Simulation Options" className="part_img" />
                <div className="part_tutorial_text">
                    <h2 className="part_title">Selecting Your Simulation Options</h2>
                    <p>After submitting your input concentrations, the "Simulation Options" section will open. This section allows you to alter how iBioSim will process your files. When you select your simulation method, keep in mind that the mutual information capacity algorithm is designed to be run using the "SSA" option. There are default values on the page, so if you don't want to alter how the simulator runs from default, you can simply press "Simulate" to begin the analysis. After pressing "Simulate" the page will refresh, allowing you to begin another run of the program. After the analysis is completed on the server, an output file will be e-mailed to the e-mail you entered in the beginning. This file is an HTML page that can be opened in your browser to view all of your simulation results. WetCoDe only supports the Chrome browser at this time. The output page may work on other browsers, but there may be undefined behavior if you chose to use one. You may download a file containing the simulation options you have selected here on the output page. See the tutorial section on downloading your files for more information.</p>
                </div>
            </div>
            <div className="part_tutorial">
                <div className="part_tutorial_text_only">
                    <h2 className="part_title">Your Various Simulation Options Explained</h2>
                    <p>The number of runs is a whole number that determines how many simulations occur at each step. Each step in the output will be the average of each simulation. The simulation time limit is an integer that determines how long the simulation runs in seconds. The simulation method allows the user to select whether the simulation runs in ODE or SSA. The print interval is an integer that determines the print interval of SSA simulations. The simulation time step is an integer or decimal number that determines the time step of SSA simulations. The minimum time step is an integer or decimal number that determines the minimum time step of SSA simulations. The random Seed is an integer that determines the random seed of SSA simulations. The absolute error is an integer, decimal number, or scientific notation number that determines the absolute error of SSA simulations. The relative Error is an integer, decimal number, or scientific notation number that determines the relative error of SSA simulations.</p>
                </div>
            </div>
            <div className="part_tutorial">
                <img src={process.env.PUBLIC_URL + "/open.png"} alt="Open With..." className="part_img" />
                <div className="part_tutorial_text">
                    <h2 className="part_title">Loading the Output Page</h2>
                    <p>Once you've recieved your e-mail with your analysis file, you must open it in a browser to examine the data. To do so, open a new tab in your browser, and navigate to your output file in a file explorer. Click and drag the file from the file explorer onto the middle of the new tab, and the page should load in. Alteratively, you can right-click the file, and select the option to "Open With...", then select your browser from the list of options. Again, our program only supports Chrome, and may have undefined behavior when using other browsers.</p>
                </div>
            </div>
            <div className="part_tutorial">
                <img src={process.env.PUBLIC_URL + "/simOutput.png"} alt="Simulation Output" className="part_img" />
                <div className="part_tutorial_text">
                    <h2 className="part_title">The Simulation Output Plot</h2>
                    <p>The "Simulation Output" section provides a series of plots that display the concentration over time of all the species in the SBML file. You can select from the dropdown one of the concentration steps from the number you defined in the "Initial Concentrations of Input" section to display on the plot including the data for the SMBL file. You can click on a species in the top legend to remove or return a species from the plot. After selecting from the dropdown which step you want to view, click "Update Graph". The new graph will be displayed, and a label for which step you chose will appear on the right.</p>
                </div>
            </div>
            <div className="part_tutorial">
                <img src={process.env.PUBLIC_URL + "/entropy.png"} alt="Entropy" className="part_img" />
                <div className="part_tutorial_text">
                    <h2 className="part_title">The Entropy Plot</h2>
                    <p>The "Entropy Plot" section will show you a graph over time of the maximum output compared to a uniformly increasing input. The y-axis shows the concentration of the output species, and the x-axis shows the concentration of your input species.</p>
                </div>
            </div>
            <div className="part_tutorial">
                <img src={process.env.PUBLIC_URL + "/precursor.png"} alt="Algorithm Information" className="part_img" />
                <div className="part_tutorial_text">
                    <h2 className="part_title">The Algorithm Information</h2>
                    <p>The "Algorithm Information" section displays the precursor information passed to the mutual information capacity algorithm.</p>
                </div>
            </div>
            <div className="part_tutorial">
                <img src={process.env.PUBLIC_URL + "/downloads.png"} alt="Downloading" className="part_img" />
                <div className="part_tutorial_text">
                    <h2 className="part_title">Downloading Your Files</h2>
                    <p>In the "Download Files" section you can download the analyzed data. The "Precursor Data" button will provide to you the precursor data passed to the mutual information algorithm. The "Reaction Map" button will download an image of your reaction map. The "Simulation Properties" button will download the file passed to iBioSim which contains the various simulation options you selected on the input page. The "Simulation Data" button will download the data for the run selected in the dropdown next to the button.</p>
                </div>
            </div>
            <div className="part_tutorial">
                <div className="part_tutorial_text_only">
                    <h2 className="part_title">Additional Information</h2>
                    <p>Although the webpage will show you the next step that must be completed on the input page, if you'd like to go back and see what you entered you can click on the "+" to re-open that section. Similarly, you can click the "-" to close the tab back up. The output page begins with all of these tabs closed by default since there isn't a particular workflow you must follow for the page to function, and you may not wish to view certian sections. If you don't recieve your data after a significant amout of time, then re-submit your file. The underlying server architecture that we use is a community computation grid, and occasionally will refuse to run files.</p>
                </div>
            </div>
        </div>
    );
};

export default PanelTutorial;