import {types} from '../actions/ActionTypes.js';

/* The default state for the application */
const defaultState = {
    shouldRequestSessionId: true,
    sessionIdAcquired: false,
    sessionIdRequested: false,
    sessionIdRequestFailed: false,
    sessionIdErrorCode: 0,
    sessionId: 0,
    fileSent: false,
    fileReceived: false,
    fileError: false,
    fileErrorCode: 0,
    fileErrorMessage: '',
    fileErrorLog: null,
    speciesList: [""],
    speciesSent: false,
    speciesError: false,
    speciesErrorCode: 0,
    speciesErrorMessage: '',
    reactionMapReceived: false,
    reactionMapText: '',
    speciesReceived: '',
    concentrationsSent: false,
    concentrationsError: false,
    concentrationsErrorCode: 0,
    concentrationsErrorMessage: '',
    simulationOptionsSent: false,
    simulationError: false,
    simulationErrorCode: 0,
    simulationErrorMessage: '',
    outputDataParsing: false,
    outputDataParsed: false,
    outputDataError: false,
    inOutPlotReceived: false,
    inOutPlotText: '',
    precursorDataReceived: false,
    precursorDataText: '',
    propertiesReceived: false,
    propertiesText: '',
    displayDataReceived: false,
    displayDataText: ''
};

const HttpReducer = (state = defaultState, action) => {
    switch(action.type) {
        case types.SESSION_ID_REQUESTED:
            return {...state, sessionIdRequested: true, sessionIdRequestFailed: false, shouldRequestSessionId: false};
        case types.SESSION_ID_ACQUIRED:
            return {...state, sessionId: action.id, sessionIdAcquired: true, sessionIdRequested: false, sessionIdRequestFailed: false};
        case types.SESSION_ID_ERROR:
            return {...state, sessionIdErrorCode: action.error, sessionIdAcquired: false, sessionIdRequested: false, sessionIdRequestFailed: true};
        case types.FILE_SENT:
            return {...state, fileSent: true, fileReceived: false, fileError: false, fileErrorLog: null};
        case types.FILE_RECEIVED:
            return {...state, fileSent: false, fileReceived: true, fileError: false, speciesList: action.species};
        case types.FILE_UPLOADED:
            return {...state, fileReceived: false};
        case types.FILE_SEND_ERROR:
        case types.FILE_READ_ERROR:
            return {...state, fileError: true, fileErrorCode: action.errorCode, fileErrorMessage: action.error, fileSent: false, fileReceived: false};
        case types.FILE_SBML_ERROR:
            return {...state, fileErrorLog: action.errorLog};
        case types.SPECIES_SENT:
            return {...state, speciesSent: true, speciesError: false, reactionMapReceived: false};
        case types.SPECIES_SEND_ERROR:
        case types.SPECIES_READ_ERROR:
            return {...state, speciesError: true, speciesErrorCode: action.errorCode, speciesErrorMessage: action.error, speciesSent: false};
        case types.REACTION_MAP_RECEIVED:
            return {...state, speciesSent: false, reactionMapReceived: true, reactionMapText: action.map};
        case types.CONCENTRATION_VALUES_SENT:
            return {...state, concentrationsSent: true, concentrationsError: false};
        case types.CONCENTRATIONS_SEND_ERROR:
        case types.CONCENTRATIONS_READ_ERROR:
            return {...state, concentrationsError: true, concentrationsErrorCode: action.errorCode, concentrationsErrorMessage: action.error, concentrationsSent: false};
        case types.SIMULATION_OPTIONS_SENT:
            return {...state, simulationOptionsSent: true, simulationError: false};
        case types.SIMULATION_SEND_ERROR:
        case types.SIMULATION_RUN_ERROR:
            return {...state, simulationError: true, simulationErrorCode: action.errorCode, simulationErrorMessage: action.error, simulationOptionsSent: false};
        case types.OUTPUT_DATA_PARSING:
            return {...state, outputDataParsing: true, outputDataParsed: false, outputDataError: false};
        case types.OUTPUT_DATA_PARSED:
            return {...state, outputDataParsing: false, outputDataParsed: true, outputDataError: false};
        case types.OUTPUT_DATA_ERROR:
            return {...state, outputDataParsing: false, outputDataParsed: false, outputDataError: true};
        case types.IN_OUT_PLOT_RECEIVED:
            return {...state, inOutPlotReceived: true, inOutPlotText: action.plot};
        case types.PRECURSOR_DATA_RECEIVED:
            return {...state, precursorDataReceived: true, precursorText: action.precursor};
        case types.PROPERTIES_RECEIVED:
            return {...state, propertiesReceived: true, propertiesText: action.plot};
        case types.DISPLAY_DATA_RECEIVED:
            return {...state, displayDataReceived: true, displayDataText: action.display};
        default:
            return state;
    };
};

export default HttpReducer;