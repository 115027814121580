import zlib from 'react-zlib-js';
import {types} from './ActionTypes.js';
import Urls from './Urls.js';
import UIActions from './UIActions.js';

/* Requests a user session when connection first established */
const requestSessionId = () => {
    return function(dispatch, getState) {
        dispatch(requestingSessionId());

        // Open a new HTTP request
        var xhr = new XMLHttpRequest();
        xhr.open('GET', Urls.getSessionIdUrl, true);
        xhr.withCredentials = true;
        xhr.setRequestHeader('Content-Type', 'application/x-www/-form-urlencoded');
        xhr.onreadystatechange = function(e) {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200) {
                    var data = JSON.parse(xhr.responseText);
                    dispatch(sessionIdAcquired(data.sessionId));
                } else {
                    dispatch(sessionIdError(xhr.status));
                };
            };
        };
        xhr.send();
    };
};

/* Action called when client is waiting on server's response to session id request */
const requestingSessionId = () => {
    return {
        type: types.SESSION_ID_REQUESTED
    };
};

/* Action called when sessionId is successfully acquired */
const sessionIdAcquired = (sessionId) => {
    return {
        type: types.SESSION_ID_ACQUIRED,
        id: sessionId
    };
};

/* Action called when sessionId request fails (server problems) */
const sessionIdError = (errorCode) => {
    return {
        type: types.SESSION_ID_ERROR,
        error: errorCode
    };
};

/* Called when user sends a file to the server */
const sendFile = (name, email, file, sessionId) => {
    return function(dispatch, getState) {
        dispatch(fileSent());

        // Open a new HTTP request
        var data = new FormData();
        data.append("sessionId", sessionId);
        data.append("name", name);
        data.append("email", email);
        data.append("file", file);
        var xhr = new XMLHttpRequest();
        xhr.open('POST', Urls.submitFileUrl, true);
        xhr.withCredentials = true;
        
        // Handle server response
        xhr.onreadystatechange = function(e) {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200) {
                    var data = JSON.parse(xhr.responseText);
                    if(data.hasOwnProperty('readError')) {
                        // There was an issue with the file submitted
                        dispatch(fileReadError(data.readError));
                        if(data.hasOwnProperty('sbmlError')) {
                            // The error was due to syntax errors in the file
                            dispatch(fileSBMLError(data.sbmlError));
                        }
                    } else if(data.hasOwnProperty('speciesList')) {
                        // File was submitted successfully
                        dispatch(fileReceived(data.speciesList));
                        // Close all sections except species input/output
                        dispatch(UIActions.resetSpeciesSelected());
                        dispatch(UIActions.allAccordionsClosed());
                        dispatch(UIActions.accordionChanged("section_species_selection"));
                    }
                } else {
                    dispatch(fileSendError(xhr.status));
                }
            };
        };
        xhr.send(data);
    };
};

/* Action called when file is being sent to the server */
const fileSent = () => {
    return {
        type: types.FILE_SENT
    };
};

/* Action called when file is received and has no errors */
const fileReceived = (speciesList) => {
    return {
        type: types.FILE_RECEIVED,
        species: speciesList
    };
};

/* Action called when file cannot be sent */
const fileSendError = (errorCode) => {
    return {
        type: types.FILE_SEND_ERROR,
        errorCode: errorCode,
        error: 'Problem occurred while uploading to server. Is it down?'
    };
};

/* Action called when file received but could not be read */
const fileReadError = (errorMessage) => {
    return {
        type: types.FILE_READ_ERROR,
        errorCode: 0,
        error: errorMessage
    };
};

/* Action called when sbml errors were found in file */
const fileSBMLError = (errorLog) => {
    return {
        type: types.FILE_SBML_ERROR,
        errorLog: errorLog
    };
};

/* Called when user sends species to the server for analysis */
const sendSpecies = (inputSpecies, outputSpecies) => {
    return function(dispatch, getState) {
        dispatch(speciesSent());

        // Open a new HTTP request
        var data = new FormData();
        data.append("inputSpecies", inputSpecies);
        data.append("outputSpecies", outputSpecies);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', Urls.analyzeSpeciesUrl, true);
        xhr.withCredentials = true;
        
        // Handle server response
        xhr.onreadystatechange = function(e) {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200) {
                    var data = JSON.parse(xhr.responseText);
                    if(data.hasOwnProperty('speciesError')) {
                        // There was an issue with the submitted species
                        dispatch(speciesReadError(data.readError));
                    } else if(data.hasOwnProperty('reactionMap')) {
                        // File was submitted successfully
                        dispatch(reactionMapReceived(data.reactionMap));
                        dispatch(UIActions.speciesSelected(inputSpecies))
                        dispatch(UIActions.allAccordionsClosed());
                        dispatch(UIActions.accordionChanged("section_reaction_map"));
                        dispatch(UIActions.accordionChanged("section_concentrations"));
                    }
                } else {
                    dispatch(speciesSendError(xhr.status));
                }
            };
        };
        xhr.send(data);
    };
};

/* Action called when species is being sent to the server */
const speciesSent = () => {
    return {
        type: types.SPECIES_SENT
    };
};

/* Action called when reaction map is received */
const reactionMapReceived = (reactionMap) => {
    return {
        type: types.REACTION_MAP_RECEIVED,
        map: reactionMap
    };
};

/* Action called when species cannot be sent */
const speciesSendError = (errorCode) => {
    return {
        type: types.SPECIES_SEND_ERROR,
        errorCode: errorCode,
        error: 'Problem occurred while sending species. Is it down?'
    };
};

/* Action called when there is an issues with the species sent */
const speciesReadError = (errorMessage) => {
    return {
        type: types.SPECIES_READ_ERROR,
        errorCode: 0,
        error: errorMessage
    };
};

/* Called when user sends concentration inputs to the server */
const submitConcentrationValues = (minimumConcentration, maximumConcentration, numberOfSteps, eventTime) => {
    return function(dispatch, getState) {
        dispatch(concentrationValuesSent());

        // Open a new HTTP request
        var data = new FormData();
        data.append("minimumConcentration", minimumConcentration);
        data.append("maximumConcentration", maximumConcentration);
        data.append("numberOfSteps", numberOfSteps);
        data.append("eventTime", eventTime);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', Urls.submitConcentrationsUrl, true);
        xhr.withCredentials = true;
        
        // Handle server response
        xhr.onreadystatechange = function(e) {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200) {
                    var data = JSON.parse(xhr.responseText);
                    if(data.hasOwnProperty('concentrationsError')) {
                        // There was an issue with the submitted concentration inputs
                        dispatch(concentrationsReadError(data.readError));
                    } else if(data.hasOwnProperty('concentrationsSuccess')) {
                        // Concentrations were submitted successfully
                        dispatch(UIActions.allAccordionsClosed());
                        dispatch(UIActions.accordionChanged("section_options"))
                    };
                } else {
                    dispatch(concentrationsSendError(xhr.status));
                }
            };
        };
        xhr.send(data);
    };
};

/* Action called when concentration inputs are being sent to the server */
const concentrationValuesSent = () => {
    return {
        type: types.CONCENTRATION_VALUES_SENT
    };
};

/* Action called when concentration inputs cannot be sent */
const concentrationsSendError = (errorCode) => {
    return {
        type: types.CONCENTRATIONS_SEND_ERROR,
        errorCode: errorCode,
        error: 'Problem occurred while sending concentration values. Is it down?'
    };
};

/* Action called when there is an issue with sent concentration values */
const concentrationsReadError = (errorMessage) => {
    return {
        type: types.CONCENTRATIONS_READ_ERROR,
        errorCode: 0,
        error: errorMessage
    };
};

/* Called when user sends simulation options to the server */
const runSimulation = (simulationMethod, numberOfRuns, timeLimit, printInterval, timeStep, minimumTimeStep, randomSeed, absoluteError, relativeError) => {
    return function(dispatch, getState) {
        dispatch(simulationOptionsSent());

        // Open a new HTTP request
        var data = new FormData();
        data.append("simulationMethod", simulationMethod);
        data.append("numberOfRuns", numberOfRuns);
        data.append("timeLimit", timeLimit);
        data.append("printInterval", printInterval);
        data.append("timeStep", timeStep);
        data.append("minimumTimeStep", minimumTimeStep);
        data.append("randomSeed", randomSeed);
        data.append("absoluteError", absoluteError);
        data.append("relativeError", relativeError);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', Urls.runSimulationUrl, true);
        xhr.withCredentials = true;
        
        // Handle server response
        xhr.onreadystatechange = function(e) {
            if(xhr.readyState === XMLHttpRequest.DONE) {
                if(xhr.status === 200) {
                    var data = JSON.parse(xhr.responseText);
                    if(data.hasOwnProperty('simulationError')) {
                        // There was an issue with the submitted concentration inputs
                        dispatch(concentrationsReadError(data.readError));
                    } else if(data.hasOwnProperty('concentrationsSuccess')) {
                        // Concentrations were submitted successfully
                        dispatch(UIActions.allAccordionsClosed());
                        dispatch(UIActions.accordionChanged("section_options"))
                    };
                } else {
                    dispatch(concentrationsSendError(xhr.status));
                }
            };
        };
        xhr.send(data);
    };
};

/* Action called when simulation options are being sent to the server */
const simulationOptionsSent = () => {
    return {
        type: types.SIMULATION_OPTIONS_SENT
    };
};

/* Action called when simulation options cannot be sent */
const simulationSendError = (errorCode) => {
    return {
        type: types.SIMULATION_SEND_ERROR,
        errorCode: errorCode,
        error: 'Problem occurred while sending simulation options. Is it down?'
    };
};

/* Action called when simulation has an error */
const simulationRunError = (errorMessage) => {
    return {
        type: types.SIMULATION_RUN_ERROR,
        errorCode: 0,
        error: errorMessage
    };
};

/* Action called when output page is visited */
const parseOutputData = (file) => {
    return function(dispatch, getState) {
        dispatch(outputDataParsing());
        dispatch(UIActions.pageStateChanged("output"));

        var reader = new FileReader();

        if(file != undefined) {
            reader.onload = function(event) {
                var data = JSON.parse(event.target.result);
                if(data.hasOwnProperty('inOutPlot')) {
                    dispatch(inOutPlotReceived(data.inOutPlot));
                }
                if(data.hasOwnProperty('reactionMap')) {
                    dispatch(reactionMapReceived(data.reactionMap));
                }
                if(data.hasOwnProperty('precursor')) {
                    dispatch(precursorDataReceived(data.precursor));
                }
                if(data.hasOwnProperty('properties')) {
                    dispatch(propertiesReceived(data.properties));
                }
                if(data.hasOwnProperty('displayData')) {
                    dispatch(displayDataReceived(data.displayData));
                }
                dispatch(outputDataParseSuccess());
                dispatch(UIActions.allAccordionsClosed());
                dispatch(UIActions.accordionChanged("section_reaction_map"));
            }.bind(this);
            reader.onerror = function(event) {
                console.error("File could not be read! Code " + event.target.error.code);
            }
            reader.readAsText(file);
            return;
        };
    };
};

/* Action called when data parameters are being parsed */
const outputDataParsing = () => {
    return {
        type: types.OUTPUT_DATA_PARSING
    };
};

/* Action called when data parameters were successfully parsed */
const outputDataParseSuccess = () => {
    return {
        type: types.OUTPUT_DATA_PARSED
    };
};

/* Action called when data parameters could not be parsed */
const outputDataParseError = () => {
    return {
        type: types.OUTPUT_DATA_ERROR
    };
};

/* Action called when input/output plot is received */
const inOutPlotReceived = (inOutPlot) => {
    return {
        type: types.IN_OUT_PLOT_RECEIVED,
        plot: inOutPlot
    };
};

/* Action called when precursor data is received */
const precursorDataReceived = (precursor) => {
    return {
        type: types.PRECURSOR_DATA_RECEIVED,
        precursor: precursor
    };
};

/* Action called when input properties are received */
const propertiesReceived = (properties) => {
    return {
        type: types.PROPERTIES_RECEIVED,
        properties: properties
    };
};

/* Action called when display data is received */
const displayDataReceived = (displayData) => {
    return {
        type: types.DISPLAY_DATA_RECEIVED,
        display: displayData
    };
};

const HttpActions = {
    requestSessionId,
    requestingSessionId,
    sessionIdAcquired,
    sessionIdError,
    sendFile,
    fileSent,
    fileReceived,
    fileSendError,
    sendSpecies,
    speciesSent,
    reactionMapReceived,
    speciesSendError,
    speciesReadError,
    submitConcentrationValues,
    concentrationValuesSent,
    concentrationsSendError,
    concentrationsReadError,
    runSimulation,
    simulationOptionsSent,
    simulationSendError,
    simulationRunError,
    parseOutputData,
    outputDataParsing,
    outputDataParseSuccess,
    outputDataParseError,
    inOutPlotReceived,
    precursorDataReceived,
    propertiesReceived,
    displayDataReceived
};

export default HttpActions;