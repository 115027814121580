// https://wetcode.unl.edu:8080
export const fullUrl = "http://wetcode.unl.edu:8080/wetcode";
export const submitFileUrl = fullUrl + "/submit_file";
export const getSessionIdUrl = fullUrl + "/get_session_id";
export const analyzeSpeciesUrl = fullUrl + "/analyze_species";
export const submitConcentrationsUrl = fullUrl + "/submit_concentrations";
export const runSimulationUrl = fullUrl + "/run_simulation";

const Urls = {
    fullUrl,
    submitFileUrl,
    getSessionIdUrl,
    analyzeSpeciesUrl,
    submitConcentrationsUrl,
    runSimulationUrl
};

export default Urls;