import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import MenubarTab from './ui/MenubarTab';
import UIActions from '../../actions/UIActions';

function Menubar() {
    // Router Interfaces
    const history = useHistory();

    // Store Interfaces
    const state = useSelector(state => state.ui);
    const dispatch = useDispatch();
    
    // Called whenever a menu tab is selected
    const pageChangedEvent = event => {
        history.push("/");
        dispatch(UIActions.pageStateChanged(event.target.value));
    }

    return(
        <div className="menubar">
            <MenubarTab tabName="tab_application"
                tabValue="application"
                tabLabel="WetCoDe UNL"
                pageSelected={state.currentPage}
                onPageChanged={pageChangedEvent} />
            <MenubarTab tabName="tab_about"
                tabValue="about"
                tabLabel="About"
                pageSelected={state.currentPage}
                onPageChanged={pageChangedEvent} />
            <MenubarTab tabName="tab_tutorial"
                tabValue="tutorial"
                tabLabel="Tutorial"
                pageSelected={state.currentPage}
                onPageChanged={pageChangedEvent} />
        </div>
    );
};

export default Menubar;