import React from 'react';
import './styles.css';

function PanelAbout() {
    /*
        This panel contains the basic about information and members of the WetCoDe team
    */
    
    return(
        <div className="panel_view" id="panel_about">
            <div className="panel_section" id="section_about">
                {/* This section contains the basic project information */}
                <h1 className="section_title" id="title_about">About the WetCoDe Project</h1>
                <p>WetCoDe is a tool usable by researchers/amateur scientists to analyze the information theory contained in a chemical reaction network. The program is designed to take in an xml file of chemical reactions (called an SBML file) and simulate that file using iBioSim. The program asks the user to provide simulation properties such as time frame, input/output species, and other parameters for the simulation to run.</p>

                <p>A file called output.html will be sent to the user's email which contains the following: a reaction map of the SBML file, several plots detailing the concentrations of the species over time, one for each input concentration, an entropy information plot, and other information theory metrics about the simulation acquired using an algorithm developed by a researcher close to the project.</p>

                <p>WetCoDe is made possible by iBioSim. The iBioSim tool is being developed by Chris Myers research group at the University of Utah. This project has involved many students including Nathan Barker, Scott Glass, Kevin Jones, Hiroyuki Kuwahara, Curtis Madsen, Nam Nguyen, Tramy Nguyen, Tyler Patterson, Nicholas Roehner, Jason Stevens, Leandro Watanabe, Michael Zhang, Zhen Zhang, and Zach Zundel.</p>

                <p>The iBioSim tool makes use of several critical community developed libraries to enable it to better support standards such as SBML, SBOL, SED-ML, and the COMBINE Archive format. In particular, we would like to acknowledge the community of developers of libSBML, JSBML, jlibSEDML, and libSBOLj, the developers of the COMBINE Archive java library at the University of Rostock, and the developers of the Virtual Parts API at Newcastle University.</p>

                <img src={process.env.PUBLIC_URL + "/bioLogo.png"} alt="Bio logo" className="logo_about" id="bio_logo" />
            </div>
            <div className="panel_section" id="section_contributors">
                {/* This section contains all the team members */}
                <h1 className="section_title" id="title_contributors">Contributors</h1>
                <div className="pane_contributor">
                    <h2 className="contributor_name">Dr. Massimiliano Pierobon</h2>
                    <img src={process.env.PUBLIC_URL + "/pierobon.png"} alt="Dr. Massimiliano Pierobon" className="photo_contributor" />
                    <p>Dr. Pierobon is currently an Assistant Professor with the Department of Computer Science & Engineering at the University of Nebraska-Lincoln. Dr. Pierobon holds also a courtesy appointment at the Department of Biochemistry, University of Nebraska-Lincoln, effective from September 2014. He received the BWN Lab Researcher of the Year Award at the Georgia Institute of Technology for his outstanding research achievements in 2011. He was also named IEEE Communications Letters 2013 Exemplary Reviewer in appreciation for his service as referee. He is a member of IEEE, ACM, and ACS. He is in the Editorial board of IEEE Transactions on Communications, in the Area of Modulation & Signal Design, with the following designation: Biochemical and Molecular Communications. His current research interests are in molecular communication theory for nanonetworks, communication engineering applied to intelligent drug delivery systems, and biological circuit network engineering. Massimiliano Pierobon received the Master of Science (B.S.+M.S.) degree in Telecommunication Engineering from the Politecnico di Milano, Milan, Italy, in 2005. During 2006, Massimiliano Pierobon worked as a researcher in the R & D department of Siemens Carrier Networks, Milan, where he coauthored two filed patents on jitter buffer management. From January 2007 to July 2009 he was a graduate research assistant at the Politecnico di Milano in the fields of signal processing and pattern recognition. In November 2008 Massimiliano Pierobon joined the BWN lab, first as a visiting researcher and, from August 2009, as a Ph.D. student. He received the Ph.D. degree in Electrical and Computer Engineering from the Georgia Institute of Technology, Atlanta, GA, in August 2013, under the guidance of Professor Ian F. Akyildiz.</p>
                </div>
                <div className="pane_contributor">
                    <h2 className="contributor_name">Zahmeeth Sayed Sakkaff</h2>
                    <img src={process.env.PUBLIC_URL + "/zameeth.png"} alt="Zahmeeth Sayed Sakkaff" className="photo_contributor" />
                    <p>Zahmeeth Sayed Sakkaff is a Ph.D candidate in the Department of Computer Science and Engineering (CSE) at the University of Nebraska-Lincoln (UNL). Currently, she is a graduate research assistant and lab manager in the molecular and biochemical telecommunications (MBiTe) lab under the supervision of Dr. Massimiliano Pierobon. She completed her MSc and M. Phil in CS at UNL and at the University of Peradeniya in Sri Lanka, respectively, and her B. Sc in CS and chemistry at the Open University of Sri Lanka. Her research interests are focused on computational biology, biochemistry, bioinformatics, molecular communication (MC), information and coding theory, machine learning, and artificial intelligence. Her current research focuses on communication and information-centric computational modeling and characterization, in particular applied to multiscale integrated biological pathways underlying the information flow in microbes and human cells, both at a single cell level, and community level. She is summer interned in 2017 and 2018 at Argonne National Laboratory. She is a recipient of the ACM NanoCom 2017 best paper in the track of MC, IEEE Conf. ICIAFs 2010 best paper in the track of intelligent machines and man-machine co-existence, SIAM2019 best poster award, 2016-2017 outstanding master’s thesis CSE-UNL, 2018-2019 dean’s fellowship for the outstanding academic performance and scholarly potential – UNL, 2017-18 CSE-UNL outstanding graduate student research award, and 2018-19 computer engineering Milton Mohr graduate fellowship-UNL.</p>
                </div>
                <div className="pane_contributor">
                    <h2 className="contributor_name">Colton Harper</h2>
                    <img src={process.env.PUBLIC_URL + "/colton.jpg"} alt="Colton Harper" className="photo_contributor" />
                    <p>Colton Harper is a senior undergraduate student at the University of Nebraska-Lincoln from Hutchinson, Kansas. Colton is majoring in Computer Science and minoring in Biology and Mathematics. He is a co-founder and senior student advisor for the UNL Computer Science and Engineering Ambassadors student organization, a member of the UNL Innocents society, a Ronald E. McNair scholar, enrolled in the University Honors Program, a program chair and grant writer for the Broader Considerations of Technology Lecture Series, a finalist for the Outstanding Student Leader Award, and recipient of the 2018 ITI Data - Vision in Technology Scholarship.Colton has been involved in research since his Freshman year where he was a member of the silver medal recipient 2016 UNL iGEM team. Colton’s present research aims to characterize the information exchange between engineered communication between biological cells. Colton intends to pursue a Ph.D. and establish his career as a research professor.</p>
                </div>
                <div className="pane_contributor">
                    <h2 className="contributor_name">Tyler Barker</h2>
                    <img src={process.env.PUBLIC_URL + "/tyler.jpg"} alt="Tyler Barker" className="photo_contributor" />
                    <p>Tyler will graduate in May, 2019 with a Bachelor of Science in Computer Engineering. With interest in synthetic biology, he is looking to pursue a graduate degree with research in the applying computing concepts to biology. Tyler hopes to better the computing field and help the public to better understand the field.</p>
                </div>
                <div className="pane_contributor">
                    <h2 className="contributor_name">Alex Enersen</h2>
                    <img src={process.env.PUBLIC_URL + "/alex.jpg"} alt="Alex Enersen" className="photo_contributor" />
                    <p>Alex is responsible for analyzing the incoming SBML file and performing a variety of functions on its content, including building the reaction map. He also conducted usability tests for the project since he has no other skills. At the time of the project, Alex is a 5th-year senior studying Computer Science, Biology, and Psychology. Synthetic biology has always been a passing interest of his, so WetCoDe seemed like a great opportunity to explore it further.</p>
                </div>
                <div className="pane_contributor">
                    <h2 className="contributor_name">Michael Mason</h2>
                    <img src={process.env.PUBLIC_URL + "/michael.jpg"} alt="Michael Mason" className="photo_contributor" />
                    <p>Michael is a junior dual majoring in Computer Science and Mathematics, as well as holding minors in Arabic and Communication Studies. He is the team's front-end developer, and responsible for creating the websites. With rare exception, anything on the WetCoDe webpages themselves was programmed by Michael. Originally interested in computer security, Michael has shifted his focus to database design, archives, and library science. He plans to attend graduate school ultimately towards a PhD.</p>
                </div>
                <div className="pane_contributor">
                    <h2 className="contributor_name">Oliver O'Brian</h2>
                    <img src={process.env.PUBLIC_URL + "/oliver.jpg"} alt="Oliver O'Brian" className="photo_contributor" />
                    <p>Oliver O'Brian is a senior at UNL pursuing a Computer Science and Math double major with a minor in Physics. Oliver is interested in a career related to back-end development, which matches what he does for the WetCoDe team. He deals with difficult tasks such as servlet programming, server management, and interfacing with the Open Science Grid in addition to many other tasks.</p>
                </div>
                <div className="pane_contributor">
                    <h2 className="contributor_name">Beau Hottovy</h2>
                    <img src={process.env.PUBLIC_URL + "/beau.jpg"} alt="Beau Hottovy" className="photo_contributor" />
                    <p>Beau Hottovy currently lives in Lincoln, Nebraska and has graduated from UNL in May 2020 with a double major in Computer Science and Physics. He is currently pursuing a career in software development and has helped develop both the PaRedox and WetCoDe projects.</p>
                </div>
            </div>
        </div>
    );
};

export default PanelAbout;