import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import HttpActions from '../../../../actions/HttpActions';

function RunSimulationButton() {
    // Store Interfaces
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    // Called when the button is clicked
    const buttonClickedEvent = event => {
        event.preventDefault();

        // Send the simulation options to the server and run the simulation
        var simulationMethod = state.ui.simulationMethod;
        var numberOfRuns = state.ui.numberOfRuns;
        var timeLimit = state.ui.timeLimit;
        var printInterval = state.ui.printInterval;
        var timeStep = state.ui.timeStep;
        var minimumTimeStep = state.ui.minimumTimeStep;
        var randomSeed = state.ui.randomSeed;
        var absoluteError = state.ui.absoluteError;
        var relativeError = state.ui.relativeError;
        dispatch(HttpActions.runSimulation(simulationMethod, numberOfRuns, timeLimit, printInterval, timeStep, minimumTimeStep, randomSeed, absoluteError, relativeError));
    };

    return (
        <button className="run_simulation_button" onClick={buttonClickedEvent} >
            Simulate
        </button>
    );
};

export default RunSimulationButton;