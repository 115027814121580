import {types} from './ActionTypes.js';

/* Called when the page state is changed from menu tabs */
const pageStateChanged = (pageSelected) => {
    return {
        type: types.PAGE_STATE_CHANGED,
        page: pageSelected
    };
};

/* Called when all accordions are closed */
const allAccordionsClosed = () => {
    return {
        type: types.ALL_ACCORDIONS_CLOSED
    };
};

/* Called when an accordion section is collapsed/uncollapsed */
const accordionChanged = (sectionName) => {
    return {
        type: types.ACCORDION_CHANGED,
        name: sectionName
    };
};

/* Called when a file is selected from explorer */
const fileUploaded = (file) => {
    return {
        type: types.FILE_UPLOADED,
        file: file
    };
};

/* Called when species selections should be reset */
const resetSpeciesSelected = () => {
    return {
        type: types.RESET_SPECIES_SELECTED
    };
};

/* Called when species are sent and input species is known */
const speciesSelected = (inputSpecies) => {
    return {
        type: types.SPECIES_SELECTED,
        species: inputSpecies
    };
};

const UIActions = {
    pageStateChanged,
    allAccordionsClosed,
    accordionChanged,
    fileUploaded,
    resetSpeciesSelected,
    speciesSelected
};

export default UIActions;