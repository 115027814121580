import React from 'react';
import './styles.css';
import {useSelector} from 'react-redux';
import {Line} from 'react-chartjs-2';

function SimulationOutputForm() {
    // Store Interfaces
    const state = useSelector(state => state);

    function parseString(inputString) {
        //split input to an array
        var formattedArray = inputString.split("\n");
        //split input into double array
        for (var i = 0; i < formattedArray.length; i++) {
            formattedArray[i] = formattedArray[i].split(",");
        }
        //change data types from strings to numbers
        for (var i = 1; i < formattedArray.length; i++) {
            for (var j = 0; j < formattedArray[0].length; j++) {
                formattedArray[i][j] = parseFloat(formattedArray[i][j]);
            }
        }
        return formattedArray;
    }

    //DOM Element Initialization
    var simulation_output_plot = null;
    if(state.http.displayDataReceived && !state.ui.section_simulation_plot_collapsed) {
        var dataArray = state.http.displayDataText.split("#####");
        var graphIndex = state.ui.simulationOutputSelection;
        var graphSelection = dataArray[graphIndex + 1];

        var datasetdata = [];
        var parsedData = parseString(graphSelection);
        for (var i = 0; i < parsedData.length - 3; i++) {
            var speciesAndName = parsedData[0][i + 1].split("$");
            datasetdata[i] = {
                data: parsedData[i + 2],
                label: speciesAndName[0],
                borderColor: speciesAndName[1],
                fill: false
            };
        };

        var plot_data = {
            labels: parsedData[1],
            datasets: datasetdata
        };

        var plot_options = {
            elements: {
                point: {
                    radius: 2
                }
            },
            scales: {
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Concentration (Mole/Liter)'
                    }
                }],
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Time (Seconds)'
                    }
                }]
            }
        };

        simulation_output_plot = <Line height={800} width={1000} data={plot_data} options={plot_options} />
    };

    return(
        <div className="section_form" id="form_simulation_output">
            {simulation_output_plot}
        </div>
    );
};

export default SimulationOutputForm;