import React from 'react';
import './Accordion.css';

function Accordion(props) {
    return(
        <div className="accordion_section" collapsed={props.collapsed}>
            <button className="accordion_bar" id={props.name} onClick={props.sectionClicked} />
            <label htmlFor={props.name} className="accordion_title">{props.title}</label>
            <div className="accordion_content">
                {props.content}
            </div>
        </div>
    );
};

export default Accordion;