import React from 'react';
import './styles.css';
import {useSelector} from 'react-redux';
import PanelApplication from './PanelApplication';
import PanelAbout from './PanelAbout';
import PanelTutorial from './PanelTutorial';

function PanelContainer() {
    // Store Interfaces
    const state = useSelector(state => state.ui);

    //DOM Element Initialization
    var panel_view = null;
    switch(state.currentPage) {
        case 'about':
            panel_view = <PanelAbout />
            break;
        case 'tutorial':
            panel_view = <PanelTutorial />
            break;
        case 'application':
        default:
            panel_view = <PanelApplication />
            break;
    }
    
    return(
        <div className="panel_container">
            {panel_view}
        </div>
    );
};

export default PanelContainer;