import React, {useEffect} from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from "react-router-dom";
import Accordion from './ui/Accordion.js';
import UIActions from '../../actions/UIActions';
import ReactionMapForm from './form/ReactionMapForm';
import SimulationOutputForm from './form/SimulationOutputForm';
import EntropyPlotForm from './form/EntropyPlotForm';
import UploadOutputFileButton from './form/ui/UploadOutputFileButton';

function PanelOutput() {
    /*
        This panel contains the simulation outputs
    */

    // Store Interfaces
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    // Called whenever an accordion section is clicked
    const accordionSectionClicked = event => {
        dispatch(UIActions.accordionChanged(event.target.id));
    }

    return(
        <div className="panel_container">
            <UploadOutputFileButton />
            <div className="panel_view" id="panel_output">
                {/* These are the expandable sections on the page containing all the simulation outputs */}
                <Accordion name="section_reaction_map" collapsed={state.ui.section_reaction_map_collapsed.toString()} title="Reaction Map" content={<ReactionMapForm />} sectionClicked={accordionSectionClicked} />
                <Accordion name="section_simulation_plot" collapsed={state.ui.section_simulation_plot_collapsed.toString()} title="Simulation Output Plot" content={<SimulationOutputForm />} sectionClicked={accordionSectionClicked} />
                <Accordion name="section_entropy_plot" collapsed={state.ui.section_entropy_plot_collapsed.toString()} title="Entropy Plot" content={<EntropyPlotForm />} sectionClicked={accordionSectionClicked} />
                <Accordion name="section_information" collapsed={state.ui.section_information_collapsed.toString()} title="Algorithm Information" sectionClicked={accordionSectionClicked} />
                <Accordion name="section_download_files" collapsed={state.ui.section_download_files_collapsed.toString()} title="Download Files"  sectionClicked={accordionSectionClicked} />
            </div>
        </div>
    );
};

export default PanelOutput;