import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import FormActions from '../../../actions/FormActions';
import AnalyzeReactionButton from './ui/AnalyzeReactionButton';

function SpeciesSelectForm() {
    // Store Interfaces
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    // Collects the species currently available to the user
    function collectSpeciesOptions() {
        return state.http.speciesList.map((item, index) =>
            <option key={index.toString()} value={index.toString()}>{item}</option>
        );
    }

    // Called when a species is selected from drop-down list
    const speciesOptionSelected = event => {
        if(event.target.name === "inputSpeciesSelect") {
            dispatch(FormActions.inputSpeciesSelected(event.target.value));
        } else {
            dispatch(FormActions.outputSpeciesSelected(event.target.value));
        }
    };

    // DOM Element Initialization
    var species_options = collectSpeciesOptions();
    var input_species_select = 
        <select className="select_species" name="inputSpeciesSelect" onChange={speciesOptionSelected}>
            {species_options} 
        </select>;
    var output_species_select = 
        <select className="select_species" name="outputSpeciesSelect" onChange={speciesOptionSelected}>
            {species_options} 
        </select>;

    return(
        <div className="section_form" id="form_select_species">
            <label className="label_species_select" id="input_species">
                Input Species:
                {input_species_select}
            </label>
            <label className="label_species_select" id="output_species">
                Output Species:
                {output_species_select}
            </label>
            <AnalyzeReactionButton />
        </div>
    );
};

export default SpeciesSelectForm;