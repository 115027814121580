import React from 'react';
import './styles.css';
import {useSelector, useDispatch} from 'react-redux';
import HttpActions from '../../../../actions/HttpActions';

function AnalyzeReactionButton() {
    // Store Interfaces
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    // Called when the button is clicked
    const buttonClickedEvent = event => {
        event.preventDefault();

        // Send the input and output species selected to the server
        var inputSpecies = state.http.speciesList[state.ui.input_species_option_selected];
        var outputSpecies = state.http.speciesList[state.ui.output_species_option_selected];
        dispatch(HttpActions.sendSpecies(inputSpecies, outputSpecies));
    };

    return (
        <button className="analyze_reaction_button" onClick={buttonClickedEvent} disabled={!state.http.fileReceived || state.http.speciesSent}>
            Analyze
        </button>
    );
};

export default AnalyzeReactionButton;